import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import TopMenu from './TopMenu'
import Footer from './Footer'
import '../stylesheets/main.scss'

const Layout = ({ children, activeDocMeta }) => {
  const data = useStaticQuery(graphql`
    query LayoutNavigation {
      prismicTopMenu {
        ...TopMenuFragment
      }
    }
  `)

  return (
    <>
      <TopMenu
        topMenu={data.prismicTopMenu.data}
        activeDocMeta={activeDocMeta}
      />
      <main className="main">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
